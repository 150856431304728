<!--
 * @Author: Sandy
 * @Date: 2023-11-01 10:08:32
 * @LastEditTime: 2024-08-22 15:17:25
 * @Description:
-->
<template>
  <div class="min-h-100vh">
    <!-- <div class="top relative z-99"> -->
    <TheHeader :key="route.fullPath"/>
    <!-- <Topbar v-if="!hideTopBar" :dark="isShadowPage"></Topbar> -->
    <!-- </div> -->
    <slot />
    <TheFooter/>
    <ClientOnly>
      <!-- <LazyChatDrawer ref="chatDrawer"/> -->
      <LoginDialog ref="loginDialog"/>
      <DownloadDialog v-model="showDownloadDialog" is-definite-time />
      <CookiesManage></CookiesManage>
      <!-- <ExclusiveOfferDialog :ref="setExclusiveDialogRef"/> -->
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
	// const chatDrawer = ref(null);
	const loginDialog = ref(null);
	const route = useRoute();
	// provide('chatDrawer', chatDrawer);
	provide('loginDialog', loginDialog);
	const showDownloadDialog = ref(false);
	const isShadowPage = ref(false);
	const copyTransparent = ref(false);
	const hideCopyright = ref(false);
	const hideTopBar = ref(false);
	onMounted(() => {
		const token = getArvinSessionToken();
		const path = route.path;
		if (!token && !getStorage('showDownloadDialog', 1) && !path.includes('user') && !path.includes('invite')) {
			setTimeout(() => {
				showDownloadDialog.value = true;
				setStorage('showDownloadDialog', '1');
				trackEvent('Dialog', 'show', 'download_auto_dialog');
			}, 5000);
		}
		checkPath();
	});

	watch(() => route.path, () => {
		checkPath();
	});

	const checkPath = () => {
		isShadowPage.value = ['/invite', '/feedback'].includes(route.path);
		hideTopBar.value = route.path.startsWith('/share/chat/');
		copyTransparent.value = ['/invite'].includes(route.path);
		hideCopyright.value = ['/user/dashboard', '/user/login', '/user/subscription'].includes(route.path);
	};

</script>
