<template>
  <div v-if="showAccept" class="cookies-manage fixed bottom-12px left-12px right-12px sm:bottom-24px sm:left-auto sm:right-24px rounded-12px bg-#fff z-999999">
    <div id="cookiesManageBox" class="max-w-634px w-full p-24px pt-36px relative">
      <div class="flex-between items-unset flex-col sm:flex-row overflow-hidden">
        <p class="flex-1 fs-14 leading-17px c-deep-90">This website uses cookies and other tracking technologies to enhance user experience and to analyze performance and traffic on our website. If we have detected an opt-out preference signal then it will be honored. Further information is available in our
          <a href="/cookies" target="_blank" class="c-info-60 decoration-none hover:opacity-80">Cookies Policy</a>
        </p>
        <div class="sm:mx-18px mx-0 w-full sm:w-153px mt-24px sm:mt-0 flex flex-col justify-between">
          <div id="cookiesAcceptAll" class="cp fs-14 font-500 h-32px flex-center rounded-16px bg-deep-90 hover:op-80 c-white mb-16px sm:mb-0" @click="gaSelect=true;accept()">Accept All</div>
          <div v-if="!isManage" id="cookiesSettingManage" class="cp fs-14 font-500 h-32px flex-center rounded-16px b-1 b-solid b-deep-90 c-deep-90 hover:opacity-80" @click="isManage=true">Manage Setting</div>
          <div v-else id="cookiesAcceptSelect" class="cp fs-14 font-500 h-32px flex-center rounded-16px b-1 b-solid b-deep-90 c-deep-90 hover:opacity-80" @click="accept()">Accept Selected</div>
        </div>
      </div>
      <div v-if="isManage" class="fs-14 mt-24px c-deep-90 leading-17px">
        <p class="max-w-300px w-full flex-between">Strictly Necessary Cookies<el-switch v-model="strictlySelect" :width="'22px'" size="small" disabled /></p>
        <p class="max-w-300px w-full flex-between mt-14px">Web Analytics Cookies<el-switch v-model="gaSelect" :width="'22px'" size="small" /></p>
      </div>
      <div id="cookiesManageClose" class="absolute right-6px top-6px cp p-6px group" @click="close">
        <svg-icon name="close" class="c-deep-90 w-14px h-14px group-hover:opacity-80"/>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
	import { getGAParams } from '~/composables/useGA';
	// import { useLocation } from '~/composables/useLocation';

	// const { getEU } = useLocation();
	const isManage = ref(false);
	const strictlySelect = ref(true);
	const gaSelect = ref(true);
	const close = () => {
		gaSelect.value = true;
		saveCookies();
	};
	const accept = () => {
		saveCookies();
	};
	const showAccept = ref(false);
	const acceptCookiesKey = 'accept_cookies';

	const saveCookies = () => {
		const { gaClientId, gaSessionId } = getGAParams();
		localStorage.setItem(acceptCookiesKey, JSON.stringify({ isAccept: true, gaSelect: gaSelect.value, gaClientId, gaSessionId }));
		!gaSelect.value && turnOffGA();
		!gaSelect.value && reportEvent('User_Disables_GA_Cookies');
		showAccept.value = false;
	};
	const turnOffGA = () => {
		const cookieStore: CookieStore = window.cookieStore;
		cookieStore.getAll().then((res) => {
			res.forEach((item) => {
				item.name.includes('_ga') && cookieStore.delete(item);
			});
		});
	};

	onMounted(async () => {
		// const isEU = await getEU();
		const acceptCookies = JSON.parse(localStorage.getItem(acceptCookiesKey) || 'null');
		if (acceptCookies) { gaSelect.value = acceptCookies.gaSelect; }
		showAccept.value = !acceptCookies;
		if (!gaSelect.value) {
			setTimeout(turnOffGA, 1000);
		}
	});

</script>

<style lang="less" scoped>
.cookies-manage {
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
</style>
